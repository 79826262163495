import React, { useState, useMemo } from 'react';
import './styles.scss';
import usersService from '../../services/users.service';
import { IAuthState, UserRole } from '../../store/auth/auth.model';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthActions from '../../store/auth/auth.actions';
import Logo from '../../assets/images/logo_horizontal.png';
import { setToken } from '../../utils/auth';

const Login: React.FC = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        setLoading(true);
        let result: IAuthState;
        try {
            result = await usersService.login(login, password);
        } catch (e) {
            setError("Usuário ou senha inválidos");
            setLoading(false);
            return;
        }
        setLoading(false);
        dispatch(
            AuthActions.setAuthData(result)
        );
        setToken(result.accessToken);
        if (result.role === UserRole.ADMIN) {
            history.push("/obras");
        } else {
            history.push("/");
        }
    }

    const validFields = useMemo<boolean>(() => {
        return !!(login && password)
    }, [login, password]);

    return (
        <div id="login-page">
            <form onSubmit={handleSubmit}>
                <div className="logo">
                    <img src={Logo} />
                    <p>Acompanhe o status de suas obras de forma simplificada.</p>
                </div>

                <input type="text" placeholder="Usuário"
                    value={login} onChange={(e) => setLogin(e.target.value)} />
                <input type="password" placeholder="Senha"
                    value={password} onChange={(e) => setPassword(e.target.value)} />

                <button type="submit" className="default-button" disabled={!validFields}>
                    {loading ? "Entrando..." : "Entrar"}
                </button>

                {/* <div className="other-options">
                    <div className="line"></div>
                    <p>OU</p>
                    <div className="line"></div>
                </div> */}

                {error && (
                    <p className="error-message">{error}</p>
                )}

                {/* <div className="forgot-password">
                    <a href="#">Esqueceu a senha?</a>
                </div> */}

            </form>
            {/* <form className="register">
                <p>Não tem uma conta? <a href="/cadastro">Cadastre-se</a></p>
            </form> */}
        </div>
    );

}

export default Login;