class CalendarUtil {

    getReadableDateFromBrDateString(date: string) {
        const splittedDate = date.split("/");
        const day = splittedDate[0];
        const month = splittedDate[1];
        const year = splittedDate[2];

        return `${day} de ${this.getReadableMonth(parseInt(month))} de ${year}`;
    }

    getReadableMonth(month: number) {
        switch (month) {
            case 1:
                return "Janeiro";
            case 2:
                return "Fevereiro";
            case 3:
                return "Março";
            case 4:
                return "Abril";
            case 5:
                return "Maio";
            case 6:
                return "Junho";
            case 7:
                return "Julho";
            case 8:
                return "Agosto";
            case 9:
                return "Setembro";
            case 10:
                return "Outubro";
            case 11:
                return "Novembro";
            case 12:
                return "Dezembro";
            default:
                return null;
        }
    }

    getActualDate() {
        const fullDate = new Date().toISOString().split(".")[0];
        const splittedDate = fullDate.split("T");
        return splittedDate[0] + ' ' + splittedDate[1];
    }

    getBrDateByDatetime(datetime: string) {
        const date = datetime.split(" ")[0]; // datetime ex: 2020-07-07 10:15:33
        const splitDate = date.split("-");
        const day = splitDate[2];
        const month = splitDate[1];
        const year = splitDate[0];
        return `${day}/${month}/${year}`;
    }



}

export default new CalendarUtil();