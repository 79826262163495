import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../store/app.state';
import { Redirect, Route } from 'react-router-dom';
import { IAuthState, UserRole } from '../store/auth/auth.model';

interface IProps {
    path: string
    component: any
}

const NonAuthRoute: React.FC<IProps> = ({ path, component }) => {

    const user = useSelector<IAppState, IAuthState>(state => state.auth);

    if (!user) {
        return (
            <Route path={path} component={component} />
        );
    } else if (user.role === UserRole.ADMIN) {
        return <Redirect to={{ pathname: "/obras" }} />
    } else {
        return <Redirect to={{ pathname: "/" }} />
    }

}

export default NonAuthRoute;