import React, { useState, useEffect, useMemo } from 'react';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { IObra } from '../../models/obra';
import LoaderBackground from '../../components/loader-background';
import obrasService from '../../services/obras.service';
import { StatusObra } from '../../models/status-obra';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';
import { ICamera } from '../../models/camera';
import camerasService from '../../services/cameras.service';
import { IUsuario } from '../../models/usuario';
import usersService from '../../services/users.service';
import { PeriodoFoto } from '../../models/periodo-foto';
import { TipoObra } from '../../models/tipo-obra';

interface IProps {
    match: any;
}

const VisualizarObraPage: React.FC<IProps> = ({ match }) => {

    const history = useHistory();

    const id = match.params.id;

    const [obra, setObra] = useState<IObra>();
    const [cameras, setCameras] = useState<ICamera[]>([]);
    const [usuarios, setUsuarios] = useState<IUsuario[]>([]);
    const [loading, setLoading] = useState(true);

    const [dialogExcluirUsuarioOpen, setDialogExcluirUsuarioOpen] = useState(false);
    const [selectedUsuario, setSelectedUsuario] = useState<IUsuario>();

    async function getObra() {
        let obra: IObra;
        try {
            obra = await obrasService.getObraById(id);
        } catch (e) {
            setLoading(false);
            toast.error("Ocorreu um erro ao buscar a obra");
            return;
        }
        setObra(obra);
        getCameras();
        getUsuarios();
    }

    async function getCameras() {
        let cameras: ICamera[];
        try {
            cameras = await camerasService.getCamerasByObraId(id);
        } catch (e) {
            setLoading(false);
            toast.error("Ocorreu um erro ao buscar as câmeras");
            return;
        }
        setCameras(cameras);
        setLoading(false);
    }

    async function getUsuarios() {
        let usuarios: IUsuario[];
        try {
            usuarios = await usersService.getUsuariosByObraId(id);
        } catch (e) {
            setLoading(false);
            toast.error("Ocorreu um erro ao buscar os usuários");
            return;
        }
        setUsuarios(usuarios);
        setLoading(false);
    }

    function openDialogExcluirUsuario(usuario: IUsuario) {
        setSelectedUsuario(usuario);
        setDialogExcluirUsuarioOpen(true);
    }

    function handleCloseDialogExcluirUsuario() {
        setDialogExcluirUsuarioOpen(false);
        setSelectedUsuario(undefined);
    }

    async function excluirUsuario() {
        setLoading(true);
        setDialogExcluirUsuarioOpen(false);
        try {
            await usersService.excluir(selectedUsuario!);
        } catch (e) {
            setLoading(false);
            toast.error("Erro ao excluir usuário. Tente novamente.");
            return;
        }
        setUsuarios(usuarios => usuarios.filter(usuario => usuario.id !== selectedUsuario?.id));
        setSelectedUsuario(undefined);
        setLoading(false);
        toast.success("Usuário excluído com sucesso!");
    }

    const dialogExcluirUsuario = useMemo(() => {
        return (
            <Dialog
                open={dialogExcluirUsuarioOpen}
                onClose={handleCloseDialogExcluirUsuario}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Excluir o usuário {selectedUsuario?.nome}?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tem certeza que deseja excluir o usuário {selectedUsuario?.nome}? Essa ação não pode ser desfeita.
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogExcluirUsuario} color="primary">
                        Cancelar
          </Button>
                    <Button onClick={excluirUsuario} color="primary">
                        Confirmar
          </Button>
                </DialogActions>
            </Dialog>
        );
    }, [dialogExcluirUsuarioOpen]);

    useEffect(() => {
        getObra();
    }, []);

    function navigateToEditarObra() {
        history.push(`/obras/${id}/editar`);
    }

    function navigateToCriarCamera() {
        history.push(`/obras/${id}/cameras/criar`);
    }

    function navigateToEditarCamera(cameraId: string) {
        history.push(`/obras/${id}/cameras/${cameraId}/editar`);
    }

    function navigateToCriarUsuario() {
        history.push(`/obras/${id}/usuarios/criar`);
    }

    function navigateToEditarUsuario(usuarioId: string) {
        history.push(`/obras/${id}/usuarios/${usuarioId}/editar`);
    }

    function navigateToVerObra() {
        history.push(`/ver-obra/${id}`);
    }

    return (
        <>
            {obra && (
                <>

                    <div id="visualizar-obra-page" className="container">

                        <div className="spad d-block d-sm-flex align-items-start">
                            <div className="d-block">
                                <h1>{obra!.nome}</h1>
                                <p className="mt-1 tempo-fotos">Tempo entre fotos: {obra!.tempoEntreFotos} {obra!.periodoFoto === PeriodoFoto.MINUTOS ? 'minutos' : 'segundos'}</p>
                                <p className="mt-1 tempo-fotos">Tipo de Obra: {obra!.tipoObra === TipoObra.LONGA ? 'Longa' : 'Curta'}</p>
                                {obra.status === StatusObra.EM_ANDAMENTO && <p className="obra-progress andamento">EM ANDAMENTO</p>}
                                {obra.status === StatusObra.FINALIZADA && <p className="obra-progress finalizada">FINALIZADA</p>}
                                <button onClick={navigateToVerObra} type="button" className="add-button mt-2 mb-4">
                                <p>Visualizar fotos da obra</p>
                            </button>
                            </div>

                            <button onClick={navigateToEditarObra} type="button" className="add-button mt-1 ml-0 ml-sm-3 mt-sm-3">
                                <p>Editar Obra</p>
                            </button>
                        </div>

                        <div className="row">
                            <div className="default-input-container col-12">
                                <div className="d-block d-sm-flex justify-content-start align-items-center">
                                    <p className="mb-0">Câmeras</p>

                                    <button onClick={navigateToCriarCamera} type="button" className="add-button ml-0 ml-sm-3 mt-sm-0">
                                        <i className="material-icons smaller">add</i>
                                        <p>Nova Câmera</p>
                                    </button>
                                </div>
                                <div className="row">
                                    {cameras.map(camera => (
                                        <div key={camera.id} className="col-12 col-md-6 col-xl-3 mt-3">
                                            <div className="camera-item">
                                                <div className="camera-header">
                                                    <p>{camera.nome}</p>
                                                </div>
                                                <div className="fotos-info">
                                                    <i className="material-icons">camera_alt</i>
                                                </div>
                                                <div className="camera-item-footer">
                                                    <button onClick={() => navigateToEditarCamera(camera.id)} type="button">EDITAR</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {!loading && cameras.length === 0 && (
                                    <p className="mt-2 font-weight-normal">Nenhuma câmera encontrada.</p>
                                )}
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="default-input-container col-12">
                                <div className="d-block d-sm-flex justify-content-start align-items-center">
                                    <p className="mb-0">Usuários</p>

                                    <button onClick={navigateToCriarUsuario} type="button" className="add-button ml-0 ml-sm-3 mt-sm-0">
                                        <i className="material-icons smaller">add</i>
                                        <p>Novo Usuário</p>
                                    </button>
                                </div>
                                <div className="row">
                                    {usuarios.map(usuario => (
                                        <div key={usuario.id} className="col-12 col-md-6 col-xl-3 mt-3">
                                            <div className="camera-item">
                                                <div className="camera-header">
                                                    <p>{usuario.nome}</p>
                                                </div>
                                                <div className="fotos-info">
                                                    <i className="material-icons">person</i>
                                                </div>
                                                <div className="camera-item-footer">
                                                    <button onClick={() => navigateToEditarUsuario(usuario.id)} type="button">EDITAR</button>
                                                    <button onClick={() => openDialogExcluirUsuario(usuario)} type="button">EXCLUIR</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {!loading && cameras.length === 0 && (
                                    <p className="mt-2 font-weight-normal">Nenhuma câmera encontrada.</p>
                                )}
                            </div>
                        </div>

                    </div>

                </>
            )}

            <LoaderBackground loading={loading} />
            {dialogExcluirUsuario}
        </>
    );

}

export default VisualizarObraPage;