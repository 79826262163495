class RouteHelper {

    get actualRoute() {
        const host = window.location.host;
        const url = window.location.href;
        const uri = url.split(host)[1];
        return uri;
    }

}

export default new RouteHelper();