import { IAuthState } from "./auth.model";
import { IAction } from "../action";

export default class AuthActions {

    static readonly SET_AUTH_DATA = "PersonsActions_SET_AUTH_DATA";
    static readonly REMOVE_AUTH_DATA = "PersonsActions_REMOVE_AUTH_DATA";

    static setAuthData(data: IAuthState | null): IAction {
        return {
            type: AuthActions.SET_AUTH_DATA,
            payload: data
        }
    }

    static removeAuthData(): IAction {
        return {
            type: AuthActions.REMOVE_AUTH_DATA,
        }
    }

}