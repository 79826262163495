import React from 'react';
import './styles.scss';

interface IProps {
    loading: boolean;
}

const LoaderBackground: React.FC<IProps> = ({ loading }) => {

    return (
        <div id="loader-background-component" className={`${loading && "show-loader"}`}>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );

}

export default LoaderBackground;