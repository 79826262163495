import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import HomePage from './pages/home';
import AppMenuWrapper from './AppMenuWrapper';
import Login from './pages/login';
import NonAuthRoute from './utils/NonAuthRoute';
import AdminRoute from './utils/AdminRoute';
import ObrasPage from './pages/obras';
import UserRoute from './utils/UserRoute';
import EditarObraPage from './pages/editar-obra';
import VisualizarObraPage from './pages/visualizar-obra';
import EditarCameraPage from './pages/editar-camera';
import EditarUsuarioPage from './pages/editar-usuario';

const Routes: React.FC = () => {

    return (
        <BrowserRouter>
            <Switch>
                {/**
                 * Rotas sem o Header
                 */}
                <NonAuthRoute path="/entrar" component={Login} />

                {/**
                 * Rotas com o Header
                 */}
                <UserRoute exact path="/" component={HomePage} />


                <AdminRoute exact path="/obras" component={ObrasPage} />
                <AdminRoute exact path="/obras/criar" component={EditarObraPage} />
                <AdminRoute exact path="/obras/:id" component={VisualizarObraPage} />
                <AdminRoute exact path="/obras/:id/editar" component={EditarObraPage} />
                <AdminRoute exact path="/ver-obra/:id" component={HomePage} />

                {/**
                 * Telas de edição de câmera
                 */}
                <AdminRoute exact path="/obras/:obraId/cameras/criar" component={EditarCameraPage} />
                <AdminRoute exact path="/obras/:obraId/cameras/:id/editar" component={EditarCameraPage} />
                {/* <AdminRoute exact path="/obras/:id/cameras" component={CamerasObraPage} /> */}

                {/**
                 * Telas de edição de usuário
                 */}
                <AdminRoute exact path="/obras/:obraId/usuarios/criar" component={EditarUsuarioPage} />
                <AdminRoute exact path="/obras/:obraId/usuarios/:id/editar" component={EditarUsuarioPage} />
                {/* <AdminRoute exact path="/obras/:id/cameras" component={CamerasObraPage} /> */}
            </Switch>
        </BrowserRouter>
    );

}

export default Routes;