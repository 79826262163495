import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../store/app.state';
import { Redirect, Route } from 'react-router-dom';
import { IAuthState, UserRole } from '../store/auth/auth.model';
import Header from '../components/header';

interface IProps {
    path: string
    component: any
    exact?: boolean
}

const AdminRoute: React.FC<IProps> = ({ path, component, exact }) => {

    const user = useSelector<IAppState, IAuthState>(state => state.auth);

    if (!user || user.role !== UserRole.ADMIN) {
        return <Redirect to={{ pathname: "/entrar" }} />
    } else {
        return (
            <>
                <Header user={user} />
                <div className="spad-header admin-screen-container">
                    <Route path={path} component={component} exact={exact} />
                </div>
            </>
        );
    }

}

export default AdminRoute;