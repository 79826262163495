import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ICamera } from '../../models/camera';
import { toast } from 'react-toastify';
import camerasService from '../../services/cameras.service';
import obrasService from '../../services/obras.service';
import { IObra } from '../../models/obra';
import LoaderBackground from '../../components/loader-background';


interface IProps {
    match: any;
}

const EditarCameraPage: React.FC<IProps> = ({ match }) => {

    const history = useHistory();

    const id = match.params.id;
    const obraId = match.params.obraId;

    const [nome, setNome] = useState("");
    const [loading, setLoading] = useState(false);
    const [camera, setCamera] = useState<ICamera>();
    const [obra, setObra] = useState<IObra>();

    useEffect(() => {
        async function getCameraById() {
            setLoading(true);
            let camera: ICamera;
            try {
                camera = await camerasService.getCameraById(id, obraId);
            } catch (e) {
                setLoading(false);
                toast.error("Câmera não encontrada.");
                history.push("/obras");
                return;
            }
            setCamera(camera);
            setNome(camera.nome);
            setLoading(false);
        }
        async function getObraById() {
            setLoading(true);
            let obra: IObra;
            try {
                obra = await obrasService.getObraById(obraId);
            } catch (e) {
                setLoading(false);
                toast.error("Câmera não encontrada.");
                history.push("/obras");
                return;
            }
            setObra(obra);
            setLoading(false);
        }
        if (id) {
            getCameraById();
        };
        getObraById();
    }, []);

    function validate() {
        if (!nome) {
            toast.error("O nome é obrigatório");
            return false;
        }
        return true;
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (!validate()) return;
        if (id) {
            editarCamera();
        } else {
            criarCamera();
        }
    }

    async function criarCamera() {
        setLoading(true);
        const camera = await camerasService.criar(obraId, nome);
        await obrasService.editar({
            ...obra!,
            cameras: [
                ...obra!.cameras,
                camera.id,
            ]
        })
        setLoading(false);
        toast.success("Câmera criada com sucesso!");
        history.push(`/obras/${obraId}`);
    }

    async function editarCamera() {
        setLoading(true);
        const camera: ICamera = {
            id,
            nome,
            obraId,
        }
        await camerasService.editar(camera);
        setLoading(false);
        toast.success("Câmera editada com sucesso!");
        history.push(`/obras/${obraId}`);
    }

    return (
        <div id="editar-camera-page" className="container">

            <div className="spad">
                <h1>{!id ? "Criar" : "Editar"} câmera</h1>
            </div>

            <form onSubmit={handleSubmit}>

                <div className="row">
                    <div className="default-input-container col-12 col-md-6 col-xl-4">
                        <p>Obra</p>
                        <input type="text"
                            value={obra ? obra.nome : ""}
                            disabled
                            className="default-input" />
                    </div>

                    <div className="default-input-container col-12 col-md-6 col-xl-4 mt-5 mt-md-0">
                        <p>Nome</p>
                        <input type="text"
                            value={nome}
                            onChange={e => setNome(e.target.value)}
                            className="default-input" />
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12 col-md-3">
                        <button type="submit" className="default-button">{id ? 'Salvar' : 'Criar'}</button>
                    </div>
                </div>

            </form>

            <LoaderBackground loading={loading} />

        </div>
    );

}

export default EditarCameraPage;