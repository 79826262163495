export default {
    api: {
        // Obra
        getObras: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/obras",
        getObraById: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/obras/:id",
        criarObra: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/obras",
        editarObra: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/obras/editar",
        excluirObra: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/obras/excluir",

        // Camera
        getCamerasByObraId: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/obras/:id/cameras",
        getCameraById: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/obras/:obraId/cameras/:id",
        criarCamera: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/obras/:id/cameras",
        editarCamera: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/cameras/editar",
        excluirCamera: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/cameras/excluir",
        getDocById: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/cameras/fotos/:id",
        getFotosByCameraIdAndData: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/cameras/:id/fotos",
        getTimelapseByCameraIdAndData: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/cameras/:id/timelapse",

        // Usuário
        login: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/auth",
        getUsuarioById: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/usuarios/:id",
        getUsuariosByObraId: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/obras/:obraId/usuarios",
        getUsuarioByUsuario: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/usuarios-by-usuario/:usuario",
        criarUsuario: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/usuarios",
        editarUsuario: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/usuarios/editar",
        excluirUsuario: "https://us-central1-obraapp-f87a9.cloudfunctions.net/app/usuarios/excluir",
    }
}