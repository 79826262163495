import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../store/app.state';
import FullImage from '../../components/full-image';
import { IAuthState } from '../../store/auth/auth.model';
import { ICamera } from '../../models/camera';
import camerasService from '../../services/cameras.service';
import { toast } from 'react-toastify';
import LoaderBackground from '../../components/loader-background';
import { IFoto } from '../../models/foto';
import calendar from '../../utils/calendar';
import { IObra } from '../../models/obra';
import obrasService from '../../services/obras.service';
import { TipoObra } from '../../models/tipo-obra';
import FullSlider from '../../components/full-slider';


interface IProps {
    children?: any;
    match: any;
}

const MY_INITIAL_PICTURES = [
    { url: "https://www.geotesc.com.br/site/wp-content/uploads/2012/10/2008-06-26-Obra-Tecnopolo.jpg" },
    { url: "https://www.geotesc.com.br/site/wp-content/uploads/2012/10/2008-06-26-Obra-Tecnopolo.jpg" },
    { url: "https://www.geotesc.com.br/site/wp-content/uploads/2012/10/2008-06-26-Obra-Tecnopolo.jpg" },
    { url: "https://www.geotesc.com.br/site/wp-content/uploads/2012/10/2008-06-26-Obra-Tecnopolo.jpg" },

]

let obra: IObra | null = null;

const HomePage: React.FC<IProps> = ({ match }) => {

    const obraIdUrlAdmin = match.params.id;

    const [loading, setLoading] = useState(true);
    const [noMoreData, setNoMoreData] = useState(false);
    const [timelapseFoundUrl, setTimelapseFoundUrl] = useState("");
    let loadingImages = false;
    

    const [selectedImage, setSelectedImage] = useState<string>();
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>();
    const [selectedVideo, setSelectedVideo] = useState<string>();
    const [cameras, setCameras] = useState<ICamera[]>([]);
    const [selectedCameraId, setSelectedCameraId] = useState<string>("");

    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);

    const [fotos, setFotos] = useState<IFoto[]>([]);

    const dispatch = useDispatch();

    const user = useSelector<IAppState, IAuthState>(state => state.auth);

    function setCameraId(cameraId: string) {
        setSelectedCameraId(cameraId);
    }

    async function getCameras() {
        let cameras: ICamera[];
        try {
            cameras = await camerasService.getCamerasByObraId(obraIdUrlAdmin ?? user.obraId!);
        } catch (e) {
            setLoading(false);
            toast.error("Ocorreu um erro ao buscar as câmeras");
            return;
        }
        setCameras(cameras);
        if (cameras.length > 0) {
            setCameraId(cameras[0].id);
        }
        setLoading(false);
    }

    async function getFotosByCameraIdAndData() {
        if (!selectedCameraId || !selectedDate) return;
        setLoading(true);
        loadingImages = true;
        let newFotos: IFoto[];
        let lastFoto: IFoto | null = null;
        if (fotos.length > 0 && fotos[fotos.length - 1].data === selectedDate) {
            lastFoto = fotos[fotos.length - 1];
        }
        try {
            newFotos = await camerasService.getFotosByCameraIdAndData(selectedCameraId, selectedDate, 12, lastFoto);
        } catch (e) {
            setLoading(false);
            toast.error("Ocorreu um erro ao buscar suas fotos");
            return;
        }
        if (newFotos.length < 12) {
            setNoMoreData(true);
        }
        setFotos(currentFotos => {
            return [...currentFotos.filter(el => el.data === selectedDate), ...newFotos]
        });
        setLoading(false);
        loadingImages = false;
    }

    async function getObra() {
        try {
            obra = await obrasService.getObraById(obraIdUrlAdmin ?? user.obraId!);
        } catch (e) {
            return;
        }
    }

    function onScroll() {
        const scrollY = document.documentElement.scrollTop + document.documentElement.clientHeight;
        const scrollHeight = document.documentElement.scrollHeight;

        if (scrollY + 150 >= scrollHeight) {
            if (!noMoreData && !loadingImages) {
                getFotosByCameraIdAndData();
                // this.page += 1;
                // this.getSimulacoes();
            }
        }

    }

    async function getTimelapseByCameraIdAndData() {
        if (!selectedCameraId || !selectedDate) return;
        let timelapseUrl: string;
        const splitDate = selectedDate.split("-");
        const year = splitDate[0];
        const month = splitDate[1];
        const date = `${year}-${month}`;
        try {
            timelapseUrl = await camerasService.getTimelapseByCameraIdAndData(selectedCameraId, obra?.tipoObra === TipoObra.CURTA ? null : date);
        } catch (e) {
            toast.error("Ocorreu um erro ao buscar a timelapse");
            return;
        }
        setTimelapseFoundUrl(timelapseUrl);
    }

    useEffect(() => {
        setNoMoreData(false);
        getFotosByCameraIdAndData();
        getTimelapseByCameraIdAndData();
    }, [selectedCameraId, selectedDate]);

    useEffect(() => {
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [fotos]);

    useEffect(() => {
        initialize();
    }, []);

    async function initialize() {
        await getObra();
        await getCameras();
    }

    function onImageClick(index: number) {
        // setSelectedImage(image);
        console.log("index: ", index);
        setSelectedImageIndex(index);
    }

    return (
        <div id="home-page">
            {selectedImage && (
                <FullImage url={selectedImage} onRequestClose={() => setSelectedImage("")} />
            )}
            {selectedVideo && (
                <FullImage isVideo={true} url={selectedVideo} onRequestClose={() => setSelectedVideo("")} />
            )}
            <div className="container">

                <div className="spad">
                    <h1>Bem Vindo, {user.name}!</h1>
                    <p>Aqui você tem acesso as fotos de sua obra. Filtre por data e câmera para achar as fotos desejadas.</p>
                </div>


                <div className="row spad-smaller align-items-end">
                    <div className="default-input-container col-12 col-sm-6 col-lg-3 mb-4 mb-sm-0">
                        <p>Data</p>
                        <input value={selectedDate} type="date" onChange={(e) => setSelectedDate(e.target.value)} />
                    </div>
                    <div className="default-input-container col-12 col-sm-6 col-lg-3">
                        <p>Câmera</p>
                        <select value={selectedCameraId} onChange={(e) => setCameraId(e.target.value)}>
                            {cameras.map(camera => (
                                <option value={camera.id} key={camera.id}>{camera.nome}</option>
                            ))}
                        </select>
                    </div>
                    {timelapseFoundUrl && (
                        <div onClick={() => setSelectedVideo(timelapseFoundUrl)} className="col-12 col-sm-6 col-lg-3">
                            <button type="button" className="add-button mt-3 mt-sm-0">
                                <i className="material-icons">play_arrow</i>
                                <p>ver timelapse do mês</p>
                            </button>
                        </div>
                    )}
                </div>

                <div className="pictures-container">
                    {fotos.length === 0 && !loading && (
                        <p className="col-12">Nenhuma foto encontrada com o filtro especificado.</p>
                    )}
                    {
                        fotos.map((foto, i) => (
                            <div key={foto.id} className="col-12 col-md-6 col-xl-4">
                                <div className="picture" onClick={() => onImageClick(i)}>
                                    <img src={foto.url} />
                                    <div className="image-info">
                                        <p>{calendar.getBrDateByDatetime(foto.data)} às {foto.hora}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            {selectedImageIndex !== undefined && (
                <FullSlider fotos={fotos.map(img => img.url)} startIndex={selectedImageIndex} onRequestClose={() => setSelectedImageIndex(undefined)} />
            )}
            <LoaderBackground loading={loading} />
        </div>
    );

}

export default HomePage;