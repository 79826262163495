import React from 'react';
import './styles.scss';
import { ISideMenuRoute } from '../../models/side-menu-route';
import routeHelper from '../../utils/routeHelper';
import { useHistory } from 'react-router-dom';

interface IProps {
    visible: boolean;
    onRequestClose: () => void;
    location?: any;
}

const SideMenu: React.FC<IProps> = ({ visible, onRequestClose, location }) => {

    const history = useHistory();

    const routes: ISideMenuRoute[] = [
        { id: 1, icon: "home", label: "Obras", route: "/obras" },
        // { id: 2, icon: "people_alt", label: "Usuários", route: "/usuarios" },
    ]

    function onRouteClick(route: ISideMenuRoute) {
        history.push(route.route);
        onRequestClose();
    }

    return (

        <div id="side-menu-component" className={`side-menu-component-width ${!visible && 'hide-side-menu-all'}`}>
            <div id="side-menu-background" className={`${!visible && 'hide-side-menu-background'}`} onClick={onRequestClose}></div>
            <div className={`side-menu-container side-menu-child-component-width ${!visible && 'hide-side-menu'}`}>
                <div className="list-items">
                    {routes.map(route => (
                        <div key={route.id} className={`item ${route.route === routeHelper.actualRoute && 'selected'}`} onClick={() => onRouteClick(route)}>
                            <i className="material-icons">{route.icon}</i>
                            <p>{route.label}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

}

export default SideMenu;