import React, { useRef, useState } from 'react';
import './styles.scss';
import { IFoto } from '../../models/foto';
import AliceCarousel from 'react-alice-carousel'

interface IProps {
    fotos: string[];
    startIndex: number;
    onRequestClose: () => void;
}

const FullSlider: React.FC<IProps> = ({ fotos, startIndex, onRequestClose }) => {

    const [currentIndex, setCurrentIndex] = useState<number>(startIndex);
    
    const handleOnDragStart = (e: any) => e.preventDefault();
    

    const carouselRef = useRef<AliceCarousel | null>();

    function slideNext() {
        setCurrentIndex(index => index + 1);
        carouselRef.current?.slideNext()
    }

    function slidePrev() {
        setCurrentIndex(index => index - 1);
        carouselRef.current?.slidePrev()
    }

    function onSlideChanged(e: any) {
        setCurrentIndex(e.item);
    }

    return (
        <div id="full-slider-component">
            <div className="background" onClick={onRequestClose}></div>
            <i className="material-icons close" onClick={onRequestClose}>close</i>
            {currentIndex > 0 && (
                <i className="material-icons arrow arrow-left" onClick={slidePrev}>keyboard_arrow_left</i>
            )}
            {currentIndex < fotos.length - 1 && (
                <i className="material-icons arrow arrow-right" onClick={slideNext}>keyboard_arrow_right</i>
            )}
            <div className="slider">
                <AliceCarousel startIndex={startIndex} autoPlay={false} onSlideChanged={onSlideChanged} infinite={false} mouseTrackingEnabled ref={(el) => carouselRef.current = el}>
                    {fotos.map((img, i) => (
                        <div key={i} className="alice-carousel-image-container">
                            <img src={img} onDragStart={handleOnDragStart} />
                        </div>
                    ))}
                </AliceCarousel>
            </div>
        </div>
    );

}

export default FullSlider;