import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { IObra } from '../../models/obra';
import obrasService from '../../services/obras.service';
import { StatusObra } from '../../models/status-obra';
import LoaderBackground from '../../components/loader-background';
import calendar from '../../utils/calendar';
import { PeriodoFoto } from '../../models/periodo-foto';
import { TipoObra } from '../../models/tipo-obra';

const ObrasPage: React.FC = () => {

    const history = useHistory();

    const [obras, setObras] = useState<IObra[]>([]);
    const [loading, setLoading] = useState(true);

    function navigateToCriarObra() {
        history.push("/obras/criar");
    }

    function navigateToEditarObra(obra: IObra) {
        history.push(`/obras/${obra.id}/editar`);
    }

    function navigateToVisualizarObra(obra: IObra) {
        history.push(`/obras/${obra.id}`);
    }

    useEffect(() => {
        async function getObras() {
            const obras = await obrasService.getObras();
            setObras(obras);
            setLoading(false);
        }
        getObras();
    }, []);


    return (
        <>
            <div id="admin-home-page" className="container">
                <div className="spad d-block d-sm-flex justify-content-between">
                    <h1>Todas as obras</h1>

                    <button onClick={navigateToCriarObra} type="button" className="add-button mt-3 mt-sm-0">
                        <i className="material-icons">add</i>
                        <p>Adicionar Obra</p>
                    </button>
                </div>

                {!loading && obras.length === 0 && (
                    <p>Nenhuma obra cadastrada.</p>
                )}

                <div className="list-obras row">

                    {obras.map(obra => (
                        <div key={obra.id} className="col-12 col-md-6 col-xl-4">
                            <div className="obra">
                                <div className="obra-text-content">
                                    <div className="d-flex align-items-center justify-content-between obra-header">
                                        <p>{obra.nome}</p>
                                        <p>{calendar.getBrDateByDatetime(obra.createdAt)}</p>
                                    </div>
                                    {obra.status === StatusObra.EM_ANDAMENTO && <p className="obra-progress andamento">EM ANDAMENTO</p>}
                                    {obra.status === StatusObra.FINALIZADA && <p className="obra-progress finalizada">FINALIZADA</p>}
                                    <p className="mb-2">Usuários: {obra.usuarios.length} / Câmeras: {obra.cameras.length}</p>
                                    <p className="mb-2">Tempo entre fotos: {obra.tempoEntreFotos} {obra.periodoFoto === PeriodoFoto.MINUTOS ? 'minutos' : 'segundos'}</p>
                                    <p className="mb-2">Tipo de Obra: {obra.tipoObra === TipoObra.LONGA ? 'Longa' : 'Curta'}</p>
                                </div>
                                <div className="obra-footer">
                                    <button type="button" onClick={() => navigateToVisualizarObra(obra)}>VISUALIZAR</button>
                                    <button type="button" onClick={() => navigateToEditarObra(obra)}>EDITAR</button>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>

            </div>

            <LoaderBackground loading={loading} />
        </>
    );


}

export default ObrasPage;