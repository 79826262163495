import { IAuthState, UserRole } from '../store/auth/auth.model';
import { CriarUsuarioDTO } from '../models/dto/criar-usuario.dto';
import { IUsuario } from '../models/usuario';
import Axios from 'axios';
import environment from '../environments/environment';

class UsersService {

    login(login: string, password: string): Promise<IAuthState> {
        return new Promise(async (resolve, reject) => {
            Axios.post<IAuthState>(environment.api.login, { login, password })
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    getUsuarioById(id: string): Promise<IUsuario> {
        return new Promise(async (resolve, reject) => {
            Axios.get<IUsuario>(environment.api.getUsuarioById.replace(":id", id))
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    getUsuariosByObraId(obraId: string): Promise<IUsuario[]> {
        return new Promise(async (resolve, reject) => {
            Axios.get<IUsuario[]>(environment.api.getUsuariosByObraId.replace(":obraId", obraId))
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    getUsuarioByUsuario(usuario: string): Promise<IUsuario> {
        return new Promise(async (resolve, reject) => {
            Axios.get<IUsuario>(environment.api.getUsuarioByUsuario.replace(":usuario", usuario))
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    criar(criarUsuarioDTO: CriarUsuarioDTO): Promise<IUsuario> {
        return new Promise(async (resolve, reject) => {
            Axios.post<IUsuario>(environment.api.criarUsuario, criarUsuarioDTO)
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }
    
    editar(usuario: IUsuario): Promise<IUsuario> {
        return new Promise(async (resolve, reject) => {
            Axios.put<IUsuario>(environment.api.editarUsuario, usuario)
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    excluir(usuario: IUsuario): Promise<void> {
        return new Promise(async (resolve, reject) => {
            Axios.post<IUsuario>(environment.api.excluirUsuario, usuario)
                .then(res => resolve())
                .catch(e => reject(e));
        })
    }

}

export default new UsersService();