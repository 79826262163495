import { IAction } from "../action";
import AuthActions from "./auth.actions";
import { IAuthState } from "./auth.model";

const initialState: IAuthState | null = null;

export function authReducer(state = initialState, action: IAction): IAuthState | null {
    switch (action.type) {
        case AuthActions.SET_AUTH_DATA:
            return action.payload;
        case AuthActions.REMOVE_AUTH_DATA:
            return null;
        default:
            return state;
    }
}