import React, { useEffect, useState, useMemo } from 'react';
import './styles.scss';
import { LabelValue } from '../../models/label-value';
import { StatusObra } from '../../models/status-obra';
import { toast } from 'react-toastify';
import obrasService from '../../services/obras.service';
import LoaderBackground from '../../components/loader-background';
import { IObra } from '../../models/obra';
import { useHistory } from 'react-router-dom';
import calendar from '../../utils/calendar';
import { CriarObraDTO } from '../../models/dto/criar-obra.dto';
import { PeriodoFoto } from '../../models/periodo-foto';
import { TipoObra } from '../../models/tipo-obra';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


interface IProps {
    match: any;
}

const EditarObraPage: React.FC<IProps> = ({ match }) => {

    const history = useHistory();

    const id = match.params.id;

    const allStatus: LabelValue[] = [
        { label: "Em andamento", value: StatusObra.EM_ANDAMENTO },
        { label: "Finalizada", value: StatusObra.FINALIZADA },
    ]

    const allPeriodosFoto: LabelValue[] = [
        { label: "Minutos", value: PeriodoFoto.MINUTOS },
        { label: "Segundos", value: PeriodoFoto.SEGUNDOS },
    ]

    const allTiposObra: LabelValue[] = [
        { label: "Longa", value: TipoObra.LONGA },
        { label: "Curta", value: TipoObra.CURTA },
    ]

    const [nome, setNome] = useState("");
    const [tempoEntreFotos, setTempoEntreFotos] = useState("");
    const [status, setStatus] = useState<any>(StatusObra.EM_ANDAMENTO);
    const [periodoFoto, setPeriodoFoto] = useState<any>(PeriodoFoto.MINUTOS);
    const [tipoObra, setTipoObra] = useState<any>(TipoObra.LONGA);
    const [loading, setLoading] = useState(false);
    const [obra, setObra] = useState<IObra>();

    const [dialogEditObraStatusOpen, setDialogEditObraStatusOpen] = useState(false);

    useEffect(() => {
        async function getObraById() {
            setLoading(true);
            let obra: IObra;
            try {
                obra = await obrasService.getObraById(id);
            } catch (e) {
                setLoading(false);
                toast.error("Obra não encontrada.");
                history.push("/obras");
                return;
            }
            setObra(obra);
            setNome(obra.nome);
            setStatus(obra.status);
            setTempoEntreFotos(obra.tempoEntreFotos.toString());
            setPeriodoFoto(obra.periodoFoto);
            setTipoObra(obra.tipoObra);
            setLoading(false);
        }
        if (id) getObraById();
    }, []);

    function validate() {
        if (!nome) {
            toast.error("O nome é obrigatório");
            return false;
        }
        if (!parseInt(tempoEntreFotos) || (parseInt(tempoEntreFotos) === 0)) {
            toast.error("O tempo entre fotos é obrigatório e precisa ser maior que 0.");
            return false;
        }
        return true;
    }

    function saveObra() {
        setDialogEditObraStatusOpen(false);
        if (!validate()) return;
        if (id) {
            editarObra();
        } else {
            criarObra();
        }
    }

    async function criarObra() {
        setLoading(true);
        const criarObraDTO: CriarObraDTO = {
            nome,
            status,
            createdAt: calendar.getActualDate(),
            cameras: [],
            usuarios: [],
            periodoFoto,
            tempoEntreFotos: parseInt(tempoEntreFotos),
            tipoObra,
        }
        const obra = await obrasService.criar(criarObraDTO);
        setLoading(false);
        toast.success("Obra criada com sucesso!");
        history.push(`/obras/${obra.id}`);
    }

    async function editarObra() {
        setLoading(true);
        const newObra: IObra = {
            id,
            nome,
            periodoFoto,
            status,
            cameras: obra!.cameras,
            usuarios: obra!.usuarios,
            createdAt: obra!.createdAt ?? calendar.getActualDate(),
            tempoEntreFotos: parseInt(tempoEntreFotos),
            tipoObra,
        }
        await obrasService.editar(newObra);
        setLoading(false);
        toast.success("Obra editada com sucesso!");
        history.push(`/obras/${id}`);
    }

    function handleCloseDialogEditObraStatus() {
        setDialogEditObraStatusOpen(false);
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (status === StatusObra.FINALIZADA) {
            setDialogEditObraStatusOpen(true);
        } else {
            saveObra();
        }
    }

    const dialogEditObraStatus = useMemo(() => {
        return (
            <Dialog
                open={dialogEditObraStatusOpen}
                onClose={handleCloseDialogEditObraStatus}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Obra finalizada?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tem certeza que deseja alterar o status da obra para finalizada? Todas as câmeras dessa obra serão finalizadas.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogEditObraStatus} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={saveObra} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }, [dialogEditObraStatusOpen]);

    return (
        <>
            <div id="editar-obra-page" className="container">

                <div className="spad">
                    <h1>{!id ? "Criar" : "Editar"} obra</h1>
                </div>

                <form onSubmit={handleSubmit}>

                    <div className="row">
                        <div className="default-input-container col-12 col-md-6 col-xl-4 mb-5">
                            <p>Status</p>
                            <select value={status} onChange={(e) => setStatus(e.target.value)}>
                                {allStatus.map(s => (
                                    <option key={s.value} value={s.value}>{s.label}</option>
                                ))}
                            </select>
                        </div>

                        <div className="default-input-container col-12 col-md-6 col-xl-4 mb-5">
                            <p>Nome</p>
                            <input type="text"
                                value={nome}
                                onChange={e => setNome(e.target.value)}
                                className="default-input" />
                        </div>

                        <div className="default-input-container col-12 col-md-6 col-xl-4 mb-5">
                            <p>Tipo de Obra</p>
                            <select value={tipoObra} onChange={(e) => setTipoObra(e.target.value)}>
                                {allTiposObra.map(s => (
                                    <option key={s.value} value={s.value}>{s.label}</option>
                                ))}
                            </select>
                        </div>

                        <div className="default-input-container col-12 col-md-6 col-xl-4 mb-5">
                            <p>Tempo entre fotos em</p>
                            <select value={periodoFoto} onChange={(e) => setPeriodoFoto(e.target.value)}>
                                {allPeriodosFoto.map(s => (
                                    <option key={s.value} value={s.value}>{s.label}</option>
                                ))}
                            </select>
                        </div>

                        <div className="default-input-container col-12 col-md-6 col-xl-4 mb-5">
                            <p>Tempo entre fotos ({periodoFoto === PeriodoFoto.MINUTOS ? "minutos" : "segundos"})</p>
                            <input type="number"
                                value={tempoEntreFotos}
                                onChange={e => setTempoEntreFotos(e.target.value)}
                                className="default-input" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-3">
                            <button type="submit" className="default-button">{id ? 'Salvar' : 'Criar'}</button>
                        </div>
                    </div>

                </form>

                {dialogEditObraStatus}

            </div>

            <LoaderBackground loading={loading} />
        </>
    );

}

export default EditarObraPage;