import { firestore } from 'firebase';
import { ICamera } from '../models/camera';
import { IFoto } from '../models/foto';
import Axios from 'axios';
import environment from '../environments/environment';

class CamerasService {

    getCamerasByObraId(obraId: string): Promise<ICamera[]> {
        return new Promise(async (resolve, reject) => {
            Axios.get<ICamera[]>(environment.api.getCamerasByObraId.replace(":id", obraId))
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    getCameraById(id: string, obraId: string): Promise<ICamera> {
        return new Promise(async (resolve, reject) => {
            Axios.get<ICamera>(
                environment.api.getCameraById
                    .replace(":obraId", obraId)
                    .replace(":id", id)
            )
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    criar(obraId: string, nome: string): Promise<ICamera> {
        return new Promise(async (resolve, reject) => {
            Axios.post<ICamera>(environment.api.criarCamera.replace(":id", obraId), { nome })
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    editar(camera: ICamera): Promise<ICamera> {
        return new Promise(async (resolve, reject) => {
            Axios.put<ICamera>(environment.api.editarCamera, camera)
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    excluir(camera: ICamera): Promise<any> {
        return new Promise(async (resolve, reject) => {
            Axios.post<ICamera>(environment.api.excluirCamera, camera)
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    async getDocById(id: string): Promise<firestore.DocumentSnapshot<firestore.DocumentData>> {
        return new Promise(async (resolve, reject) => {
            Axios.get<firestore.DocumentSnapshot<firestore.DocumentData>>(environment.api.getDocById.replace(":id", id))
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    getFotosByCameraIdAndData(cameraId: string, data: string, limit: number = 12, startAfterFoto: IFoto | null = null): Promise<IFoto[]> {
        return new Promise(async (resolve, reject) => {
            Axios.post<IFoto[]>(environment.api.getFotosByCameraIdAndData.replace(":id", cameraId), { data, limit, startAfterFoto })
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    getTimelapseByCameraIdAndData(cameraId: string, data: string | null = null): Promise<string> {
        return new Promise(async (resolve, reject) => {
            Axios.post<string>(environment.api.getTimelapseByCameraIdAndData.replace(":id", cameraId), { data })
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

}

export default new CamerasService();