import React, { useState } from 'react';
import './styles.scss';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { useDispatch } from 'react-redux';
import AuthActions from '../../store/auth/auth.actions';
import { IAuthState, UserRole } from '../../store/auth/auth.model';
import SideMenu from '../side-menu';
import Logo from '../../assets/images/logo_horizontal.png';

interface IProps {
    user: IAuthState
}

const Header: React.FC<IProps> = ({ user }) => {

    const dispatch = useDispatch();

    const [showBoxShadow, setShowBoxShadow] = useState(false);

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);

    window.addEventListener("scroll", (e) => {
        // setShowBoxShadow(document.documentElement.scrollTop > 0);
        setShowBoxShadow(false);
    })

    function handleExit() {
        dispatch(
            AuthActions.removeAuthData()
        )
    }

    return (
        <>
            <header id="header-component" className={`container ${showBoxShadow ? "box-shadow-enabled" : ""}`}>
                <div className="logo">
                    {user.role === UserRole.ADMIN && (
                        <i onClick={() => setIsSideMenuVisible(!isSideMenuVisible)} className="material-icons">menu</i>
                    )}
                    <img src={Logo} />
                </div>
                <div className="user"
                    ref={ref}
                    onClick={() => setIsComponentVisible(!isComponentVisible)}>
                    <p>{user.name}</p>
                    <i className="material-icons">play_arrow</i>
                    {isComponentVisible && (
                        <div className="user-list">
                            <li onClick={handleExit}>Sair</li>
                        </div>
                    )}
                </div>
            </header>


            {user.role === UserRole.ADMIN && (
                <SideMenu visible={isSideMenuVisible} onRequestClose={() => setIsSideMenuVisible(false)} />
            )}
        </>
    );

}

export default Header;