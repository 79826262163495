import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ICamera } from '../../models/camera';
import { toast } from 'react-toastify';
import camerasService from '../../services/cameras.service';
import obrasService from '../../services/obras.service';
import { IObra } from '../../models/obra';
import LoaderBackground from '../../components/loader-background';
import { IUsuario } from '../../models/usuario';
import usersService from '../../services/users.service';
import { CriarUsuarioDTO } from '../../models/dto/criar-usuario.dto';


interface IProps {
    match: any;
}

const EditarUsuarioPage: React.FC<IProps> = ({ match }) => {

    const history = useHistory();

    const id = match.params.id;
    const obraId = match.params.obraId;

    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [nomeUsuario, setNomeUsuario] = useState("");
    const [senha, setSenha] = useState("");

    const [loading, setLoading] = useState(false);
    const [usuario, setUsuario] = useState<IUsuario>();
    const [obra, setObra] = useState<IObra>();

    async function getUsuarioById() {
        setLoading(true);
        let usuario: IUsuario;
        try {
            usuario = await usersService.getUsuarioById(id);
        } catch (e) {
            setLoading(false);
            toast.error("Câmera não encontrada.");
            history.push("/obras");
            return;
        }
        setUsuario(usuario);
        setNome(usuario.nome);
        setEmail(usuario.email);
        setNomeUsuario(usuario.usuario);
        setSenha(usuario.senha);
        setLoading(false);
    }

    async function getObraById() {
        setLoading(true);
        let obra: IObra;
        try {
            obra = await obrasService.getObraById(obraId);
        } catch (e) {
            setLoading(false);
            toast.error("Câmera não encontrada.");
            history.push("/obras");
            return;
        }
        setObra(obra);
        setLoading(false);
    }

    useEffect(() => {
        
        if (id) {
            getUsuarioById();
        };
        getObraById();
    }, []);

    function validate() {
        if (!nome || !email || !nomeUsuario || !senha) {
            toast.error("Todos os campos são obrigatórios.");
            return false;
        }
        return true;
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (!validate()) return;
        if (id) {
            editarUsuario();
        } else {
            criarUsuario();
        }
    }

    async function criarUsuario() {
        setLoading(true);
        const criarUsuarioDTO: CriarUsuarioDTO = {
            nome,
            obraId,
            usuario: nomeUsuario,
            email,
            senha,
        }
        let usuario;
        try {
            usuario = await usersService.criar(criarUsuarioDTO);
        } catch (e) {
            toast.error(e);
            setLoading(false);
            return;
        }
        await obrasService.editar({
            ...obra!,
            usuarios: [
                ...obra!.usuarios,
                usuario.id,
            ]
        })
        setLoading(false);
        toast.success("Usuário criado com sucesso!");
        history.push(`/obras/${obraId}`);
    }

    async function editarUsuario() {
        setLoading(true);
        const usuario: IUsuario = {
            id,
            nome,
            obraId,
            usuario: nomeUsuario,
            email,
            senha,
        }
        try {
            await usersService.editar(usuario);
        } catch (e) {
            toast.error(e);
            setLoading(false);
            return;
        }
        setLoading(false);
        toast.success("Usuário editado com sucesso!");
        history.push(`/obras/${obraId}`);
    }

    return (
        <div id="editar-usuario-page" className="container">

            <div className="spad">
                <h1>{!id ? "Criar" : "Editar"} usuário</h1>
            </div>

            <form onSubmit={handleSubmit}>

                <div className="row">
                    <div className="default-input-container col-12 col-md-6 col-xl-4 mb-5">
                        <p>Obra</p>
                        <input type="text"
                            value={obra ? obra.nome : ""}
                            disabled
                            className="default-input" />
                    </div>

                    <div className="default-input-container col-12 col-md-6 col-xl-4 mb-5">
                        <p>Nome</p>
                        <input type="text"
                            value={nome}
                            onChange={e => setNome(e.target.value)}
                            className="default-input" />
                    </div>

                    <div className="default-input-container col-12 col-md-6 col-xl-4 mb-5">
                        <p>Email</p>
                        <input type="text"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className="default-input" />
                    </div>

                    <div className="default-input-container col-12 col-md-6 col-xl-4 mb-5">
                        <p>Nome de usuário</p>
                        <input type="text"
                            value={nomeUsuario}
                            onChange={e => setNomeUsuario(e.target.value)}
                            className="default-input" />
                    </div>

                    <div className="default-input-container col-12 col-md-6 col-xl-4 mb-5">
                        <p>Senha</p>
                        <input type="text"
                            value={senha}
                            onChange={e => setSenha(e.target.value)}
                            className="default-input" />
                    </div>

                </div>

                <div className="row">
                    <div className="col-12 col-md-3">
                        <button type="submit" className="default-button">{id ? 'Salvar' : 'Criar'}</button>
                    </div>
                </div>

            </form>

            <LoaderBackground loading={loading} />

        </div>
    );

}

export default EditarUsuarioPage;