import React from 'react';
import './styles.scss';

interface IProps {
    url: string;
    isVideo?: boolean;
    onRequestClose: () => void
}

const FullImage: React.FC<IProps> = ({ url, onRequestClose, isVideo }) => {

    return (
        <div id="full-image-component">
            <div className="background" onClick={onRequestClose}></div>
            <i className="material-icons" onClick={onRequestClose}>close</i>
            {!!isVideo ?
                (
                    <video controls>
                        <source src={url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                )
                : (
                    <img src={url} />
                )
            }
        </div>
    );

}

export default FullImage;