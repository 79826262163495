import { IObra } from '../models/obra';
import { CriarObraDTO } from '../models/dto/criar-obra.dto';
import Axios from 'axios';
import environment from '../environments/environment';

class ObrasService {

    getObras(): Promise<IObra[]> {
        return new Promise(async (resolve, reject) => {
            Axios.get<IObra[]>(environment.api.getObras)
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    getObraById(id: string): Promise<IObra> {
        return new Promise(async (resolve, reject) => {
            Axios.get<IObra>(environment.api.getObraById.replace(":id", id))
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    criar(criarObraDTO: CriarObraDTO): Promise<IObra> {
        return new Promise(async (resolve, reject) => {
            Axios.post<IObra>(environment.api.criarObra, criarObraDTO)
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    editar(obra: IObra): Promise<IObra> {
        return new Promise(async (resolve, reject) => {
            Axios.put<IObra>(environment.api.editarObra, obra)
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }

    excluir(obra: IObra): Promise<any> {
        return new Promise(async (resolve, reject) => {
            Axios.post<IObra>(environment.api.excluirObra, obra)
                .then(res => resolve(res.data))
                .catch(e => reject(e));
        })
    }


}

export default new ObrasService();