export interface IAuthState {
    id: string
    name: string
    username: string
    role: string
    obraId?: string
    accessToken: string
}

export enum UserRole {
    ADMIN = "ADMIN",
    USER = "USER"
}