import axios from 'axios';
import { getToken } from './auth';
import { store } from '../store';
import AuthActions from '../store/auth/auth.actions';

axios.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axios.interceptors.response.use(response => {
    return response;
}, (error) => {
    if (error.response && error.response.status) {
        store.dispatch(AuthActions.setAuthData(null));
        localStorage.clear();
        window.location.reload();
    }
    return Promise.reject(error.response);
})
